<template>
  <nav class="z-[60] navbar navbar-expand-md fixed-top bg-white">
    <div class="container text-center">
      <p class="font-catarell w-100 text-[0.6rem] md:text-[0.8rem] py-[0.2px]">
        Enjoy Free Shipping On Orders Over $50 |
        <a href="https://linktr.ee/asixthcoffee">Let's Meet Locals</a>
      </p>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
.as-info {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

a {
  text-decoration: underline;
  color: #062CB3;
}

@media (max-width: 768px) {
  .as-info {
    font-size: 0.5rem;
    line-height: 1.75rem;
  }
}
</style>

